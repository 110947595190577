
.print-only{
  display: none;
}
@page{
  size: A4;
}
@media print {

  html, body{
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    font-size: 12pt;
    background-color: #fff!important;
    color: #000!important;
    font-family: serif;
  }
  *{
    max-width: 100% !important;
  }
  .container{
    padding: 0 !important;
  }
  h1, h2, h3, h4, h5, h6, blockquote, ul, ol {
    color: #000!important;
    line-height: 1.2em !important;
  }
  
  h2{
      font-size: 2.5rem !important;
      margin-top: 1em !important;
      margin-bottom: 0.7em !important;
      line-height: 1.2em !important;
      padding-left: 0 !important;
  }
  h3{
      font-size: 2rem !important;
      padding-left: 40px !important;
      margin-bottom: 0.5em !important;
      line-height: 1.2em !important;
      padding-left: 0 !important;
  }
  h4{
      font-size: 1.7rem !important;
      padding-left: 60px !important;
      margin-bottom: 0.3em !important;
      line-height: 1.2em !important;
      padding-left: 0 !important;
  }
  p, ul, ol, li{
    font-size: 1.2rem !important;
    line-height: 1.2em !important;
  }
  .admin-tabs{
    display: none !important;
  }
  .hat{
    margin-left: 0 !important;
    margin-right: 0!important;
  }
  .printable {
    display: block; /* affichage des elements de classe printable */
  }
  .print-only{
    display: block;
  }
  p, blockquote {
    orphans: 3; /* pas de ligne seule en bas */ 
    widows: 3; /* pas de ligne seule en haut */
  }
  blockquote, ul, ol {
    page-break-inside: always; /* pas de coupure dans ces elements */
    page-break-after: avoid; /* pas de saut aprÃ¨s ces elements */
    page-break-before: avoid; /* pas de saut aprÃ¨s ces elements */
  }
  h1 {
    /*page-break-before: always;  chaque titre commence sur une nouvelle page */
  }
  h1, h2, h3, caption {
    page-break-after: avoid; /* pas de saut aprÃ¨s ces elements */
    margin: 0;
  }
  a {
    color: #000!important;
    text-decoration: underline!important;
  }
  /*Affichage des URL des liens*/
  a[href]:after {
    content: " (" attr(href) ")";
    display: block !important;
    width: 100% !important;
    text-align: left !important;
    float: none !important;
    font-size: 1.2rem !important;
  }

  nav, menu, form:not(.printable), aside, button, audio, video, #menu, #main-menu, #cookies{
    display: none !important;
  }  
  header{
    display: block !important;
  }
  section{
    page-break-before: always !important;
  }
  #skipnavigation{
    display: none !important;
  }
  .debug-master{
    display: none !important;
  }
  .region-nav-tools{
    display: none !important;
  }
  .region-post-header{
    display: none !important;
  }
  .h1-raw.print-only{
    margin-top: 0.3em;
    margin-bottom: 0.74em;
  }

  // RTE
  .video-embed-field-responsive-video:after{
    display: none !important;
  }
  .owl-stage{
    left: auto !important;
    width: auto !important;
    display: flex;
    flex-wrap: wrap;
  }
  .owl-item.cloned{
    display: none !important;
  }
  [class*="main__slider-item"]{
    width: 70mm !important;
    height: 70mm !important;
  }
  [class*="-thumbnail"]{
    display: none !important;
  }
  [class*="-main__text"]{
    display: none !important;
  }
  .owl-carousel .owl-item{
    width: auto !important;
  }
  [class*="-main__slide"]{
    background-color: none !important;
  }
  .rte-item:not(.m-grid-teaser-trombi),
  .a-infos-pratiques .infos-list__item{
    page-break-inside: avoid; /* pas de coupure dans ces elements */
  }
  .a-figure figure{
    max-width: 65mm !important;
  }
  .a-highlight-content{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .a-infos-pratiques__title{
    padding: 0 !important;
  }
  .gridy__item{
    width: 50% !important;
  }
}  