// BreakPoints
$break-small: 767;
$break-large: 1280; // Cette valeur doit être égale à la largeur du container + X pixel. Le passage en tablette se fera dont à X pixel de plus que la largeur du container principal, permettant de ne jamais avoir le contenu collé au bord de l'écran.
$break-vertical: 750;
$container-width: 1200;

//~#COLORS
	$core-color: #c3916e;
	$core-color-font: #000; 
	$second-color: #000; 
	$second-color-font: #fff;
	$text-color: #767676;
	$success-color : #2f8530;    
	$error-color: #b21212;
	$page-bg-color: #FFFFFF;
	$description-slider: #505050;
	$footer-color-font:#212121;
//#COLORS



// Hauteur des champs de formulaires
$form-el-height: 35;
   
// Fonts
$fancy-font: 'Rozha One';//ex site-font
$main-font: 'Roboto'; 

// Transition
$t-bam: cubic-bezier(.85,.01,0,1);

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
// $margin-item-rte: 4.02em;
$margin-item-rte: 2em;
$margin-p: 1.34em;

$mt-h1: 2em; $mb-h1: 1.5em;
$mt-h2: 2em; $mb-h2: 1em;
$mt-h3: 1.111em; $mb-h3: 0.74em;
$mt-h4: 1.666em; $mb-h4: 0.555em;
$mt-h5: 2em; $mb-h5: 1em;
$mt-h6: 1.8em; $mb-h6: 1em;

// Map engine
$icon-color: $text-color;
$icon-color-active: $second-color;