html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  // font: inherit;
  // font-size: 100%;
  // line-height: 1.2em;
  vertical-align: baseline;
}

*{
  box-sizing: border-box;

  &:before, &:after{
    box-sizing: border-box;
  }
}

html {
  font-size: 10px;
}


ol, ul { 
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

// Texte
p, h1, h2, h3, h4, h5, div, span{
  word-wrap: break-word;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
button{
  padding: 0px;
  border-width:0px;
  background-color: transparent;
  cursor: pointer;
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    padding-bottom: 0px;
  }
}
button::-moz-focus-inner { 
  border: 0; 
  padding: 0; 
  margin-top:-2px; 
  margin-bottom: -2px; 
}
// Box-sizing
a{
  box-sizing: border-box;
  
  &.external_link{
    background: none;
    padding: 0;
  }
}
.hidden{
  display: none;
}
.clearfix{
  clear: both;
}